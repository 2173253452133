body {
  -webkit-font-smoothing: antialiased;
}

[data-aos^="wipe"][data-aos^="wipe"] {
  &.aos-animate {
    &:after {
      @apply w-full;
    }
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

[data-aos^="InUp"][data-aos^="InUp"] {
  opacity: 0;
  transform: translate3d(0, 40px, 0);

  &.aos-animate {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
}

.aligncenter {
  @apply m-auto;
}

.alignleft {
  @apply float-left mr-5;
}

.alignright {
  @apply float-right ml-5;
}

.img-invert-white {
  img {
    filter: brightness(0) invert(1);
  }
}

.img-invert-black {
  img {
    filter: brightness(0);
  }
}

.img-object-contain {
  img {
    @apply object-contain;
  }
}

.img-object-cover {
  img {
    @apply object-cover;
  }
}

.img-object-bottom {
  img {
    @apply object-bottom;
  }
}

.img-h-full {
  img {
    @apply h-full w-auto;
  }
}

.img-w-full {
  img {
    @apply w-full;
  }
}

.img-full {
  img {
    @apply h-full w-full;
  }
}

.img-mx-auto {
  img {
    @apply mx-auto;
  }
}

.v-align {
  @apply flex items-center;
}

.sticky-sidebar {
  @screen md {
    @apply sticky;
    top: 120px;
  }
}

.pagination {
  .page-numbers {
    @apply px-4 transition-all duration-150 ease-in-out;

    &:hover {
    }

    &.current {
    }

    &.next,
    &.prev {
      @apply px-0;

      &:hover {
        @apply opacity-50;
      }
    }
  }
}
