
.competency-checker {

	.select-css {
		@apply w-full text-text text-sm font-semibold bg-tertiary;
		padding: 14px 20px 14px 20px;
		-webkit-appearance: none;
		letter-spacing: -0.5px;
		box-sizing: border-box;
		-moz-appearance: none;
		appearance: none;
		max-height: 45px;
		max-width: 370px;
		display: block;
		margin: 0;

		@media (max-width:$md) {
			@apply mb-5;
			max-width: none;
		}
	}

	.select-css::-ms-expand {
		display: none;
	}

	.select-css:hover {
		border-color: #888;
	}

	.select-css:focus {
		outline: none!important;
	}

	.select-css option {
		font-weight:normal;
	}

	.below-table {
		picture {
			max-height: 150px;
			max-width: 150px;
		}
	}

}