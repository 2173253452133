
.page--404 {
	@apply bg-primary;
	
	.back {
		z-index: -1;
	}

	.main-splash {
		strong {
			@apply text-white;
		}

		.block-container {
			@apply max-w-8xl;

			.content-outer {
				.content {
					@apply md:max-w-lg;
				}
			}
		}
	}

	.footer-request-demo {
		@apply hidden;
	}
}