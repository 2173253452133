$brand: "/wp-content/themes/lexonis/dist/images/brand/Lexonis_RGB_Logo_Dark_Bg_vector.svg";
$brand-inverted: "/wp-content/themes/lexonis/dist/images/brand/lexonis-logo-black.svg";

html {
  &.page-style-inverted {
    .header {
      @apply bg-white;

      #brand {
        background: url($brand-inverted);
        background-size: 100% 100%;
      }

      .tg-hamburger {
        span {
          @apply bg-header;
        }
      }
    }
  }
}

.header {
  @apply sticky top-0 z-40 w-full bg-primary;

  .header-inner{
    @apply h-auto md:h-24 md:py-7 py-5;
  }
}

.announcement-bar p a{
  color: #005655;
}

#brand {
  background: url($brand) no-repeat;
  background-size: 100% 100%;
  text-indent: -1000em;
  width: 120px;
  height: 34px;

  &.brand-inverted {
    background: url($brand-inverted);
    background-size: 100% 100%;
  }

  @media (max-width:$md) {
    height: 24px;
    width: 80px;
  }
}

.header__nav__container {
  @media (min-width:768px) {
    display: flex!important;
  }
}

body.nav-open {
  overflow: hidden;
  width: 100%;

  .mobile-nav {
    transform: translateY(0);
  }

  @media(max-width:$md) {
    .header__nav__container {
      .sub-menu {
        display: none;
      }
    }
  }

  @screen md {
    overflow: initial;
    height: auto;
    width: 100%;

    .mobile-nav {
      transform: translateY(-100%);
    }
  }
}

.tg-hamburger-outer {
  &.open {
    @apply right-7;
  }

  .tg-hamburger {
    @apply cursor-pointer flex items-center;

    body.nav-open & {
      div {
        span {
          @apply bg-secondary;

          &:first-child {
            transform: rotate(-45deg);
            top: 9px;
          }

          &:last-child {
            transform: rotate(45deg);
            bottom: 9px;
          }
        }
      }
    }

    div {
      @apply relative inline-block align-middle;
      height: 20px;
      width: 25px;
    }

    span {
      @apply bg-white transition-all duration-200 ease-in-out absolute left-0;
      height: 2px;
      width: 25px;
      content: "";

      &:first-child {
        top: 6px;
      }

      &:last-child {
        bottom: 6px;
      }
    }
  }
}

.sub-menu-- {
  &.nav--solutions {
    @apply left-0;

    .menu-nib {
      @apply -left-2;
    }
  }

  &.nav--about {
    @apply right-0;

    .menu-nib {
      @apply right-5;
    }
  }
}

.hub-nav {
  li {
    @apply inline-block mr-10 font-semibold text-sm uppercase tracking-wider;

    a {
      @apply inline-block pb-11 border-b-4 border-transparent;
    }

    &.current-menu-item {
      a {
        @apply border-secondary;
      }
    }

    @media (max-width:$md) {
      @apply mr-5;
    }
  }
}

.nav--learn-hub {
	right: 0;
}

.nav--learn-hub .menu-nib {
	right: 6.5rem;
}



.sub-menu{
  max-width: 1000px;
}