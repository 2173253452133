.faqs-block{

    .block-heading{

        @media (min-width: $md) {
            max-width: 284px;
            min-width: 284px;
        }

        h2{
            font-size: 64px;
            line-height: 1.1;
            color: #83BC56;
        }
    }

    .faq-section{

        h3{
            font-size: 28px;
            line-height: 1.1;
            color: #83BC56;

            @media (min-width: $md) {
                font-size: 40px;
            }
        }

        .faq-card{
            border-bottom: 1px solid rgba(35, 85, 83, 0.25);

            &.open{

                .faq-chevron{
                    transform: rotate(180deg);
                }
            }

            .faq-answer{

                @media (min-width: $md) {
                    font-size: 20px;
                }
            }
        }
    }
}