$font-path: "/wp-content/themes/lexonis/dist/fonts";
$arrow-green: "/wp-content/themes/lexonis/dist/images/icons/arrow_green.svg";
$arrow-black: "/wp-content/themes/lexonis/dist/images/icons/arrow_black.svg";
$blockquote: "/wp-content/themes/lexonis/dist/images/misc/blockquote_icon.svg";

@font-face {
  src: url("#{$font-path}/Averta-Regular.otf") format("opentype");
  font-family: "averta";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  src: url("#{$font-path}/Averta-Semibold.otf") format("opentype");
  font-family: "averta";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  src: url("#{$font-path}/Averta-Bold.otf") format("opentype");
  font-family: "averta";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  src: url("#{$font-path}/Halyard_Display_Regular.otf") format('opentype');
  font-family: 'HalyardDisplay';
  font-style: swap;
  font-weight: 400;
}

@font-face {
  src: url("#{$font-path}/Halyard_Display_Bold.otf") format('opentype');
  font-family: 'HalyardDisplay';
  font-style: swap;
  font-weight: 700;
}

@font-face {
  src: url("#{$font-path}/Halyard_Display_SemiBold.otf") format('opentype');
  font-family: 'HalyardDisplay';
  font-style: swap;
  font-weight: 600;
}

.u-h1 {
  @apply text-h1Mobile md:text-4xl lg:text-5xl xl:text-h1;
  margin-bottom: 20px;
}

.u-h2 {
  @apply text-header text-4xl lg:text-h2;
  font-size: 2.25rem;
  margin-bottom: 20px;
  @media screen and (min-width: 1024px) {
    font-size: 3rem;
  }
}

.u-h3 {
  margin-bottom: 20px;
}

p {
  @apply tracking-tight;
}

blockquote {
  @apply bg-tertiary relative;
  padding: 3rem 5.5rem 3rem 5.5rem!important;
  quotes: none!important;

  p {
    &:last-child {
      margin-top: 60px!important; 
    }
  }

  &:before {
    background: url($blockquote) no-repeat;
    position: absolute;
    height: 25px;
    width: 25px;
    content: '';
    left: 30px;
    top: 30px;
  }

  @media (max-width:$md) {
    padding: 5rem 1.5rem 3rem 1.5rem!important;

    &:before {
      left: 25px;
    }

    p {
      padding: 0px!important;
    }
  }
}

.section-title {
  @apply text-23px leading-7 text-header mb-3 md:mb-5;
}

.body-action-link {
  @apply text-base text-primary font-semibold inline-block transition-all;

  &:after {
    background-position: center center;
    background: url($arrow-green);
    background-size: 100% 100%;
    display: inline-block;
    margin-left: 15px;
    margin-top: 8px;
    height: 10px;
    width: 15px;
    content: '';
  }

  &.type-plain {
    @apply text-text;

    &:after {
      background: url($arrow-black);
    }
  }

  &:hover {
    @apply text-secondary;
  }
}



















