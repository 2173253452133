.main-splash {

    &.white-bg-home{
        background-color: #F0F6F4;
        margin-bottom: 0;
        padding-top: 3rem;
        padding-bottom: 3rem;

        .content{
            max-width: 735px;

            @media (min-width: 768px) {
                padding-right: 40px;
            }
        }

        h1{
            font-size: 40px;
            color: #83BC56;

            @media (min-width: 1020px) {
                font-size: 64px;
            }
        }

        .prose{
            max-width: 500px;

            p{
                font-size: 12px;
                color: #235553;

                @media (min-width: 768px) {
                    font-size: 16px;
                }
            }
        }

        .btn{
            background-color: #83BC56;
        }
    }
}