.statement-block{

    &.bg-tertiary{

        p{
            color: #235553;
        }
    }

    .statement-heading{
        font-size: 64px;
        font-weight: bold;
        line-height: 1;

        &.smaller-heading-mob{
            font-size: 32px;
            line-height: 1.1;

            @media (min-width: $md) {
                font-size: 64px;
                line-height: 1;
            }
        }

        span{
            color: #83BC56;

            &:nth-child(2){
                color: #A2CD80;
            }

            &:nth-child(3){
                color: #C1DDAA;
            }
        }
    }

    p{
        font-size: 28px;
        line-height: 1.2;
        color: #fff;

        @media (min-width: $md) {
            font-size: 48px;
        }
    }
}