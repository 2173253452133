
// Desktop
.featured-card {
    position: relative;
    background: rgb(0,86,85);
    background: linear-gradient(90deg, rgba(56,94,95,1) 0%, rgba(59,100,99,1) 30%);

    &:hover{
        background: #83BC56;

        .chevron-cta svg path {
            fill: white;
        }

        .card-border{
            height: 4px;
            background-color: #235553;
        }

        .btn{
            background-color: #fff;
            color: black;
        }
    }

    h3{
        line-height: 1.2;

        @media (min-width:1100px) {
            height: 112px;
        }
    
        @media (min-width:1280px) {
            height: 80px;
        }
    
        @media (min-width:1536px) {
            height: 56px;
        }
    }

    p{
        line-height: 1.4;
        opacity: .8;
    }

    img{
	    max-width: 136px;
        height: 130px;
        object-fit: contain;
    }

    .card-border{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #83BC56;
    }

    .btn{
        line-height: 1.1;
        max-height: none;
    }
}

// Mobile
.featured-card-mobile{
    background: #83BC56;

    h3{
        font-size: 28px;
        line-height: 1.2;
    }

    p{
        font-size: 18px;
        line-height: 1.3;
        opacity: .8;
    }

    .chevron-cta{
        font-size: 18px;
    }

    img{
        max-width: 300px;
        height: 260px;
        object-fit: contain;
        margin: 30px auto 0;
    }
}

.chevron-cta{
    font-size: 16px;
    font-weight: 600;
    gap: 8px;
}

// Splide styling
.splide__arrow--prev{
    background-color: #265B59;
}

.splide__pagination {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.splide__pagination li{
    display: flex;
    justify-content: center;
    align-items: center;
}

.splide__pagination__page {
    width: 6px;
    height: 6px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: #fff;
    opacity: .4;
    cursor: pointer;
}

.splide__pagination__page.is-active {
    width: 8px;
    height: 8px;
    opacity: 1;
}

.splide__arrow--next{
    padding-top: 2px;
    border-bottom: 2px solid #83BC56;
}