
$nav-arrow: "/wp-content/themes/lexonis/dist/images/misc/menu-arrow-green.svg";

footer {

  .footer-logo{
    width: 114px;
  }

  .footer-menu {
    ul {
      @apply mb-10 md:mb-0;
      list-style: none;

      &.sub-menu {
        margin-top: 0px;
      }
    }

    li {
      @apply inline-block md:block mb-2 mr-3 md:mr-0 md:mb-5;
      padding-left: 0px;
      list-style: none;

      &:before {
        display: none;
      }

      a {
        @apply text-white no-underline transition-all;

        &:hover {
          @apply text-secondary;
        }
      }

      .title {
        a {
          @apply block w-full tracking-wider uppercase text-secondary font-semibold clear-both mb-1 md:mb-5;
        }
      }
    }

    @media (max-width:$md) {
      ul {
        @apply max-w-none border-b-2 border-white border-opacity-20 pb-7 mb-8;

        &.sub-menu {
          @apply border-b-0 pb-0 mb-0 mt-4;

          li {
            @apply mb-4;
          }
        }

        &#menu-footer-contact {
          @apply hidden;
        }
      }

      li {
        @apply w-full max-w-none mr-0;

        .title {
          @apply relative cursor-pointer;

          a {
            &:after {
              background: url($nav-arrow);
              @apply absolute;
              height: 6px;
              content: '';
              width: 9px;
              right: 0px;
              top: 8px;
            }
          }

          &.open {
            a {
              &:after {
                transform: rotate(180deg);
              }
            }
          }
        }

        .sub-menu {
          @apply hidden;
        }
      }
    }
  }

  .credits {
    a {
      @apply text-white no-underline;
    }
  }
}