.newsletter-sign-up{

    .content-wrap{
        max-width: 820px;
    }

    h2{
        font-size: 32px;
        font-weight: 700;
        line-height: 1.1;
        color: #C1DDAA;
        text-align: center;

        @media (min-width: $md) {
            font-size: 64px;
            text-align: left;
        }
    }

    .prose{
        font-size: 12px;
        text-align: center;

        @media (min-width: $md) {
            font-size: 16px;
            text-align: left;
        }
    }

    .new-form-styles{
        max-width: 535px;
        margin: 0 auto;

        @media (min-width: $md) {
            margin: 0;
        }

        input[type="text"],
        input[type="email"]{
            margin-bottom: 0;
        }

        .submit-btn{
            margin-top: 30px;
            text-align: center;

            @media (min-width: $md) {
                text-align: left;
            }

            .wpcf7-spinner{
                position: absolute;
                top: 30px;

            }
        }
    }

    .graphic{
        position: absolute;
        bottom: 0;
        right: 0;
    }
}