.just_text {
  h3 {
    font-weight: 600;
    font-size: 23px;
    line-height: 33px;
    text-align: left;
    color: #2e2e2e;
    margin-bottom: 10px;
  }
  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 29px;
    text-align: left;
    color: #2e2e2e;
    margin-bottom: 1em;
  }
}

.four_blocks {
  .block-set {
    display: flex; //remove this to show unequal sizes
    margin-bottom: 30px;
    .inner {
      background: #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      padding-bottom: 115px;
      font-size: 18px;
      line-height: 29px;
      font-weight: normal;
      text-align: left;
      color: #3a3a3a;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      position: relative;
      .section-title {
        font-weight: normal;
        font-size: 23px;
        line-height: 33px;
        background-color: #005655;
        text-align: left;
        color: #fff;
        background: #005655;
        display: flex;
        align-items: center;
        min-height: 120px;
        padding: 10px 30px 10px 30px;
        @media (min-width: $lg) {
          padding: 10px 40px 10px 40px;
        }
        @media (min-width: $xl) {
          padding: 10px 80px 10px 80px;
        }
      }
      .initial_text {
        position: relative;
        padding: 30px 30px 0 30px;
        @media (min-width: $lg) {
          padding: 30px 40px 0 40px;
        }
        @media (min-width: $xl) {
          padding: 30px 80px 0 80px;
        }
      }
      .read-more-text {
        display: none;
        padding: 30px 30px 0 30px;
        @media (min-width: $lg) {
          padding: 30px 40px 0 40px;
        }
        @media (min-width: $xl) {
          padding: 30px 80px 0 80px;
        }
      }
      .plus-button {
        background: #1b5655;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 47px;
        width: 47px;
        border-radius: 50px;
        right: 30px;
        bottom: 38px;
        position: absolute;
        @media (min-width: $lg) {
          right: 47px;
        }
        cursor: pointer;
        .line1 {
          width: 19px;
          height: 2px;
          background-color: #67c300;
        }
        .line2 {
          left: 14px;
          top: 22px;
          position: absolute;
          width: 19px;
          height: 2px;
          background-color: #67c300;
          transition: transform 300ms ease;
          transform-origin: center;
          transform: rotate(90deg) scale(1);
        }
        &.active {
          .line2 {
            transform-origin: center;
            transition: transform 300ms ease;
            transform: rotate(90deg) scale(0);
          }
        }
      }
    }
  }
}

.contact_form {
  position: relative;
  overflow: hidden;
  .fifty {
    position: absolute;
    left: 0;
    top: 0;
    height: 958px;
    width: 100%;
    background: #1b5655;
  }
  h2,
  .prose {
    color: #fff;
  }
  .form-wrap {
    position: relative;
    .dot-pic {
      position: absolute;

      right: 0;
      top: -38px;
      @media (min-width: $md) {
        right: 87px;
        top: -79px;
      }
    }

    form {
      .white-form {
        background: #fff;
        box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.16);
        width: 100%;
        max-width: 996px;
        padding: 24px;
        position: relative;
        z-index: 1;
        @media (min-width: $md) {
          padding: 54px;
        }
      }
    }
  }
  .block-container {
    position: relative;

    z-index: 1;
    .message-wrapper {
      margin-top: 1.75rem;
      margin-bottom: 1.75rem;
    }
    input,
    textarea {
      border-radius: 6px;
      background: transparent;
      border: 1px solid #3a3a3a;
    }
    textarea {
      margin-bottom: 40px;
    }
    .small-message {
      max-width: 636px;

      font-weight: normal;
      font-size: 18px;
      line-height: 29px;
      margin-bottom: 1.75rem;
      text-align: left;
      color: #3a3a3a;
      a {
        text-decoration: underline;
        color: #67c300;
      }
    }
    .consent {
      margin-bottom: 1.75rem;
      .wpcf7-list-item {
        margin: 0 30px 0 0;
        label {
          display: flex;
          align-items: center;
          .wpcf7-list-item-label {
            margin-left: 20px;
            order: 2;
          }
          [type="checkbox"] {
            order: -1;
            margin-top: -12px;
            border: none;
            &:checked {
              &:after {
                background-color: #67c300;
              }
            }
            &:after {
              content: "";
              background-color: #fff;
              margin-right: 20px;
              width: 29px;
              height: 29px;
              border-radius: 3px;
              border: 1px solid #3a3a3a;
              display: block;
            }
          }
        }
      }
    }
    .submit-wrapper {
      input {
        background: #67c300;
      }
    }
  }
}

.text_and_image {
  margin-bottom: 0 !important;
  &.left-position {
    .rightside {
      @media (min-width: $md) {
        padding-left: 0;
        order: -1;
        padding-right: 27px;
      }
      @media (min-width: $lg) {
        padding-left: 0;
        padding-right: 57px;
      }
    }
    .leftside {
      @media (min-width: $md) {
        padding-left: 27px;
        padding-right: 0;
      }
      @media (min-width: $lg) {
        padding-left: 57px;
        padding-right: 0;
      }
    }
  }
  .flex-container {
    align-items: center;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    @media (min-width: $md) {
      flex-direction: row;
    }
  }
  .rightside {
    @media (min-width: $md) {
      padding-left: 27px;
    }
    @media (min-width: $lg) {
      padding-left: 57px;
    }
    .inner {
      @media (min-width: $md) {
        max-width: 384px;
      }
      @media (min-width: $lg) {
        max-width: 684px;
      }
    }
  }
  .leftside {
    text-align: left;
    padding-bottom: 40px;
    @media (min-width: $md) {
      padding-right: 27px;
      padding-bottom: 0;
    }
    @media (min-width: $lg) {
      padding-right: 57px;
      padding-bottom: 0;
    }
    .inner {
      width: 100%;
      max-width: 502px;
    }
    h2 {
      font-weight: normal;
      font-size: 48px;
      line-height: 55px;
      text-align: left;
      color: #2e2e2e;
      margin-bottom: 30px;
    }
  }
  &.green {
    background: #1b5655;
    .leftside {
      color: #fff;
      h2 {
        color: #fff;
      }
    }
  }
}

.four_blocks_with_picture {
  .picture-wrapper {
    height: 286px;
    width: 100%;
    border-radius: 0 100px 0 0;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    img {
      object-position: center center;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      max-width: none;
      max-height: none;
      -o-object-fit: cover;
      object-fit: cover;
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
  .block-set {
    display: flex; //remove this to show unequal sizes
    margin-bottom: 30px;
    flex-direction: column;
    .inner {
      background: #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      padding-bottom: 115px;
      font-size: 18px;
      line-height: 29px;
      font-weight: normal;
      text-align: left;
      color: #3a3a3a;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      position: relative;
      .section-title {
        font-weight: normal;
        font-size: 23px;
        line-height: 33px;
        background-color: #005655;
        text-align: left;
        color: #fff;
        background: #005655;
        display: flex;
        align-items: center;
        min-height: 120px;
        padding: 10px 30px 10px 30px;
        @media (min-width: $lg) {
          padding: 10px 40px 10px 40px;
        }
        @media (min-width: $xl) {
          padding: 10px 80px 10px 80px;
        }
      }
      .initial_text {
        position: relative;
        padding: 30px 30px 0 30px;
        @media (min-width: $lg) {
          padding: 30px 40px 0 40px;
        }
        @media (min-width: $xl) {
          padding: 30px 80px 0 80px;
        }
      }
      .read-more-text {
        display: none;
        padding: 30px 30px 0 30px;
        @media (min-width: $lg) {
          padding: 30px 40px 0 40px;
        }
        @media (min-width: $xl) {
          padding: 30px 80px 0 80px;
        }
      }
      .plus-button {
        background: #1b5655;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 47px;
        width: 47px;
        border-radius: 50px;
        right: 30px;
        bottom: 38px;
        position: absolute;
        @media (min-width: $lg) {
          right: 47px;
        }
        cursor: pointer;
        .line1 {
          width: 19px;
          height: 2px;
          background-color: #67c300;
        }
        .line2 {
          left: 14px;
          top: 22px;
          position: absolute;
          width: 19px;
          height: 2px;
          background-color: #67c300;
          transition: transform 300ms ease;
          transform-origin: center;
          transform: rotate(90deg) scale(1);
        }
        &.active {
          .line2 {
            transform-origin: center;
            transition: transform 300ms ease;
            transform: rotate(90deg) scale(0);
          }
        }
      }
    }
  }
}

body.page--google_template {
  .main-splash {
    @media (min-width: $md) {
      .md\:max-w-sm {
        max-width: 34rem;
      }
      picture {
        text-align: center;
        display: block;
        img {
          display: inline-block;
        }
      }
    }
    @media only screen and (min-width: 1145px) {
      picture {
        text-align: center;
        display: block;
        img {
          display: inline-block;
          min-width: 300px;
        }
      }
    }
  }
}
