body.post-type-archive-downloads {
  @media (max-width: $lg) {
  }
  @media (max-width: $md) {
  }
  .post-grid .u-h2 {
    text-align: left;
  }
}

.downloads-archive {
  background: #ebf4f04d;

  @media (max-width: $md) {
    padding-top: 68px;
  }
  img {
    width: 100%;
  }
  .hub-nav {
    margin-top: 0;
    li {
      margin-right: 30px;
      a {
        color: #2e2e2e;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 0.75rem;
      }
    }
  }
  h1 {
    @media (max-width: $md) {
      max-width: 350px;
    }
  }
}

//single downloads page
.downloads-main-content .leftside {
  margin-right: 3rem;
}

.downloads-block .body-action-link {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  // width: 100%;
  left: 1.75rem;
  right: 0;
  margin: 0;
  height: 54px;
  background: #eee;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  display: flex;
  align-items: center;
  margin-bottom: -25px;
  &:after {
    background: url("/wp-content/themes/lexonis/dist/images/icons/arrow_green_dark.svg")
      no-repeat center center / contain;
    margin-top: 5px;
  }
}

.downloads-header-picture {
  padding-top: 96px;
  @media (max-width: $md) {
    padding-top: 68px;
  }
  img {
    width: 100%;
    height: 672px;
    @media (max-width: $md) {
      height: 400px;
    }
  }
}

.download-form-wrapper {
  margin-top: 62px;
  form {
    input[type="text"],
    input[type="email"],
    textarea {
      height: 56px;
      border-radius: 6px;
      background: transparent;
      border: 1px solid #3a3a3a;
      margin-bottom: 22px;
      margin-top: 10px;
      padding: 10px;
    }
    input[type="submit"] {
      margin-top: 20px;
    }
  }
  form[data-status="sent"] {
    display: none;
  }
}

.back-button {
  height: 54px;
  background: #eeeeee;
  a:after {
    display: none;
  }
  a:before {
    background-position: center center;
    background: url(/wp-content/themes/lexonis/dist/images/icons/arrow_green_dark.svg);
    background-size: 100% 100%;
    display: inline-block;
    margin-right: 15px;
    margin-top: 8px;
    height: 10px;
    width: 15px;
    transform: rotate(180deg);
    content: "";
    margin-top: 5px;
  }
  .block-container {
    align-items: center;
    display: flex;
    height: 54px;
  }
}

// Download post / download form block
.download-form .text{
  @media (min-width: $lg) {
    padding-right: 30px;
  }
}

.download-content p{
  margin-bottom: 1rem;
}

.download-form-wrapper{
  margin-top: 42px;
}
.downloads-main-content .desc p{
  margin-bottom: 1rem;
}

.download-form-wrapper form input[type=text],
.download-form-wrapper form input[type=email]{
  padding: 10px 20px;
}

.wpcf7-download .wpcf7-btn{
  display: inline-block;
}

.form-consent{
  font-size: 0.75rem;
  line-height: 1.2rem;
  margin-top: 15px;
}

.download-form-wrapper form input.wpcf7-submit{
  display: inline-block;
  margin-top: 0;
}

@media (max-width: 767px) {
  .single-downloads .main-splash{
      padding-top: 68px;
  }

  .downloads-main-content .leftside{
      margin-right: 0;
  }

  .download-form-wrapper{
      position: relative;
      padding-bottom: 18px;
  }

  .download-form-wrapper .wpcf7-spinner{
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      left: 0;
      right: 0;
  }
}


// Download popup
.ebook-popup{
  opacity: 0;
  visibility: hidden;
  background: rgba(0,0,0,.9);
  z-index: 100;
  transition: all .2s;
  padding-top: 30px;
}
.ebook-popup.open{
  opacity: 1;
  visibility: visible;
}
.preview-wrap{
  max-width: 700px;
}
.pdf-preview{
  width: 100%;
  height: calc(100vh - 150px);
}
.ebook-inner{
  max-width: 980px;
  overflow: auto;
  height: calc(100vh - 200px);
}

.ebook-inner .download-form-wrapper{
  margin-top: 0;
}

.ebook-inner .download-form-wrapper form input[type=text], 
.ebook-inner .download-form-wrapper form input[type=email] {
  height: 40px;
  padding: 10px;
  margin-top: 0;
  margin-bottom: 10px;
}

@media screen and (min-width: 767px) {

  .ebook-popup{
      padding-top: 0;
  }

  .ebook-inner .download-form-wrapper{
      max-width: 280px;
      padding-top: 2.25rem;
      padding-left: 0;
      padding-bottom: 2rem;
  }

  .pdf-preview{
      width: 100%;
      height: 500px;
  }

  .ebook-inner{
      height: auto;
  }

}