.product-competency-table {
	tr {
		td {
			@apply pt-7 pl-4 pr-4 md:pl-10 md:pr-10 lg:pl-20 lg:pr-20 pb-7 border-r border-gray-200;

			&:first-child {
				@apply pl-12 pr-12 border-r border-gray-200 text-left;
			}

			&:last-child {
				@apply border-0;
			}

			img {
				@apply inline-block;
			}
		}
	}

	thead {
		tr {
			td {
				&.single_table_header {
					text-align: center;

					img {
						display: inline-block;
						max-width: 160px;
					}
				}
			}
		}
	}

	tbody {
		tr {
			&:nth-child(odd) {
				@apply bg-gray-50;
			}
		}
	}
}

.competency-data-table {
	tr {
		td {
			@apply pt-6 pb-6 pl-4 pr-4 md:pl-10 md:pr-10 lg:pl-20 lg:pr-20 border-r border-gray-200;

			&:first-child {
				@apply text-left;
			}

			&:last-child {
				@apply border-0;
			}
		}
	}

	thead {
		tr {
			td {
				@apply pt-8 pb-8;
			}
		}
	}

	tbody {
		tr {
			&:nth-child(odd) {
				@apply bg-tertiary;
			}

			td {
				&:last-child {
					@apply text-secondary;
				}
			}
		}
	}
}