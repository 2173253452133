.page--case-study {

	@media (max-width:$md) {
		.main-splash {
			@apply pb-10;
		}

		.body-content-with-sidebar {
			.body-content {
				h2, h3, h4, p, ul, ol {
					//@apply px-6 md:px-0;
				}
			}
		}
	}

}