.page-id-342 {
	@media (max-width:$md) {
		main.content {
			@apply flex flex-col;

			section {
				&:first-child {
					order: 1;
				}

				&:nth-child(2) {
					order: 2;

					.inner-container {
						div {
							&:first-child {
								@apply order-2;
							}

							&:last-child {
								@apply mb-5 mt-8 order-1;
							}
						}
					}
				}

				&:nth-child(3) {
					order: 4;
				}

				&:nth-child(4) {
					order: 3;
				}

				&:nth-child(5) {
					order: 5;
				}

				&:nth-child(6) {
					order: 6;
				}
			}
		}
	}
}