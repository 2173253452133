$green-dot-pattern: "/wp-content/themes/lexonis/dist/images/misc/green_dot_pattern.svg";
$green-dot-light-bg: "/wp-content/themes/lexonis/dist/images/misc/bg_light.svg";

.ajax-loader {
	display: none!important;
}

.page--form {
	&.page-id-615 {
		.default-large-image-text-arrangement {
			.prose {
				@apply m-0;
			}
		}
	}

	.back {
		z-index: -1;
	}

	.main-splash {
		strong {
			@apply text-white;
		}

		.block-container {
			@apply max-w-8xl;

			.content-outer {
				.content {
					@apply md:max-w-lg;
				}
			}
		}
	}

	.fullscreen-bg {
		background-size: cover;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 92%;
		z-index: -1;
		max-width: 87rem;
		margin: 0 auto;
		padding: 96px 2.5rem 0;
		overflow: hidden;
		
		@media (max-width: $lg) {
			display: none;
		}
	}

	.form-embed {
		.form-embed-inner {
			&:before {
				background: url($green-dot-pattern);
				position: absolute;
				height: 160px;
				width: 160px;
				content: '';
				left: -50px;
				z-index: -1;
				top: -50px;

				@media(max-width:$md) {
					@apply hidden;
				}
			}
		}

		.form-embed-inner-light {
			position: relative;
		}
	}

	.footer-request-demo {
		@apply hidden;
	}
}
