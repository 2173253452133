.default-text-image-layout{
    

    .eyebrow-title{
        color: #83BC56;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 18px;
        line-height: 1.3;

        @media (min-width: $md) {
            font-size: 16px;
        }
    }
}
