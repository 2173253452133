
.page--inner {

	.back {
		z-index: -1;
	}

	.main-splash {
		@apply pb-24 md:pb-20 lg:pb-8;

		&.type-large-image {
			@apply mb-20 md:mb-10 lg:mb-20;
		}

		.block-container {
			//@apply max-w-8xl;

			.content-outer {
				//@apply mt-0 md:mt-8 lg:mt-12 xl:mt-16 md:pl-20 md:pr-10 self-start;
				
				@apply md:pl-10 md:pr-10;

				.content {
					@apply md:max-w-xl;
				}
			}
		}
	}

	.default-text-image-layout {
		@apply mt-16 md:mt-24 lg:mt-32;

		&.left {
			.image {
				@apply md:mr-12 order-1;

				@media(max-width:$md) {
					@apply mr-0;
				}
			}

			.text {
				@apply pr-1 order-2;

				.prose {
					@apply max-w-lg;
				}

				@media(max-width:$md) {
					@apply pr-0;

					.prose {
						@apply max-w-none;
					}
				}
			}
		}

		&.right {

			.block-container {
				//@apply pr-8;

				@media (max-width:$md) {
					//@apply pr-6;
				}
			}

			.text {
				@apply pr-8 order-2 md:order-1;

				@media(max-width:$md) {
					@apply pl-0 pr-0;
				}
			}

			.image {
				@apply ml-14 -mr-2 order-1 md:order-2;

				@media(max-width:$md) {
					@apply ml-0 mr-0;
				}
			}
		}
	}

	@media(max-width:$md) {
		.slides {
			@apply pt-0;
		}
	}
}