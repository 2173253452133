
$line: "/wp-content/themes/lexonis/dist/images/misc/dashed-line-curve.svg";

.image-text-timeline {

	.items{

		&.reduce-space-between{
			gap: 3rem;

			.single-item:not(:last-child):before{

				@media (min-width:$md) {
					content: '';
					display: block;
					width: 1px;
					height: calc(100% - 148px);
					background-color: #67C300;
					position: absolute;
					right: -1.5rem;
					bottom: 0;
				}
			}

			.single-item:after{
				right: -165px;
			}

			.single-item{

				.section-title{
					font-size: 20px;
				}
				
				.prose{

					p{
						font-size: 16px;
					}
				}
			}
		}
	}

	.single-item {

		@apply relative;

		picture {
			@apply z-10 bg-white;
		}

		&:after {
			@apply absolute;

			background: url($line);
			right: -175px;
			width: 230px;
			height: 40px;
			content: '';
			z-index: -1;
			top: 20px;

	    	@media (max-width:$md) {
	    		@apply hidden;
	    	}
		}

		&:nth-child(2n+2) {
			&:after {
				transform: rotate(180deg);
				top: 50px;
			}		
		}

		&:last-child {
			&:after {
				@apply hidden;
			}		
		}
	}

}