.slides {
	@apply pt-20 md:pt-40 md:mb-52;
	position: relative;

	.client-image {
		max-width: 190px;
	}

	&.has_background {
		&:before {
			@apply bg-primary;
			position: absolute;
			height: 575px;
			content: '';
			width: 100%;
			z-index: 0;
			left: 0px; 
			top: 0px;
		}
	}

	@media (max-width:$md) {
		&.has_background {
			&:before {
				@apply hidden;
			}
		}
	}
}