.btn {
  @apply py-2.5 px-6 text-white text-base font-semibold rounded shadow bg-secondary cursor-pointer transition-all duration-300;
  max-height: 45px;

  &:hover {
    @apply bg-tertiary text-text;
  }

  &.btn-white {
    @apply bg-white text-black;

    &:hover {
      @apply opacity-75;
    }
  }

  &.btn-outline--white {
    @apply text-white bg-transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
  }

  &.btn-outline--black {
    @apply text-black bg-transparent;
    border: 1px solid rgba(0, 0, 0, 0.4);

    &:hover {
    }
  }

  &.btn-pale-green{
    background-color: #83BC56;
  }
}

.text-link {
  @apply inline-flex font-semibold leading-none items-center text-base transition-all duration-200 ease-in-out;

  &.text-link--white {
    @apply text-white;

    &:hover {
      @apply text-gray-50;
    }
  }
}


// Social sharing / Add to any
.addtoany_content{
  display: flex;
  align-items: center;
  margin-top: 40px !important;

  .addtoany_header{
    font-weight: 700;
    color: #235553;
    margin: 3px 20px 0 0;
  }

  .a2a_kit{
    display: flex;
    align-items: center;
    column-gap:10px;

    a{
      img{
        margin: 0;
      }
    }
  }
}