
$nav-arrow: "/wp-content/themes/lexonis/dist/images/icons/nav_arrow.svg";

.image-accordion {
	@apply mt-16 md:mt-24 lg:mt-32;

	.accoridion-image {
		&.active {
			@apply block;
		}
	}

	.toggle {
		.content {
			@apply hidden;

			p {
				@apply text-base lg:text-lg;
			}
		}

		.nav-arrow {
			@apply relative;

			&:after {
				@apply absolute right-0;

				background-position: center right;
				background: url($nav-arrow);
				background-size: 100% 100%;
				transform: rotate(180deg);
				margin-top: 7px;
				height: 14px;
				width: 16px;
				content: '';
			}
		}

		&.active {
			@apply border-b-0 border-t-4 border-primary shadow py-7 mb-10;

			.nav-arrow {
				&:after {
					transform: rotate(0deg);
				}
			}

			.section-title {
				@apply text-primary mb-2;
			}

			.content {
				@apply inline-block;
			}
		}
	}
}