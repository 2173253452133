
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;

$white: #FFF;

$images-path: "/wp-content/themes/lexonis/dist/images";

.block-container {
	@apply w-full max-w-7xl mx-auto px-6 md:px-10;
	box-sizing: border-box;
}

section {
	@apply mb-24 md:mb-32;

	&.main-splash {
		@apply mb-0;
	}
}

.shapes_circle {
	background: url('#{$images-path}/competency/competency_pattern.png');
	background-position: center center;
	background-size: 100% 100%;
	position: absolute;
	height: 250px;
	width: 250px;
	content: '';
	z-index: 0;
}

.shapes-tick {
	&:before {
		background: url('#{$images-path}/icons/tick.svg');
		background-position: center center;
		background-size: 100% 100%;
		position: absolute;
		height: 579px;
		width: 702px;
		content: '';
		right: 30px;
		z-index: 0;
		top: -15px;

		@media (max-width:$md) {
			margin-right: auto;
			margin-left: auto;
			height: 365px;
			width: 420px;
			bottom: 0px;
			right: 0px;
			left: 0px;
			top: auto;
		}
	}
}

.menu-nib {
	border-right: 15px solid transparent;
	border-left: 15px solid transparent;
	border-bottom: 15px solid white;
	height: 0;
	width: 0;
}

.contains-icon {
  img {
  	min-height: 50px;
  	max-height: 50px;
	width: auto;
  }
}

.prose.text-white{

	strong{
		color: #fff;
	}
}