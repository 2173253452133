.page-id-664 {
	@media (max-width:$lg) {
		.main-splash {
			@apply mb-20;
		}
	}

	@media (max-width:$md) {
		.main-splash {
			@apply mb-24 pb-0;
		}

		.default-text-image-layout {
			&.middle {
				@apply mb-10;
				
				.block-container {
					div {
						&:first-child {
							@apply order-2 mb-0;
						}

						&:last-child {
							@apply order-1 mb-10;
						}
					}
				}
			}
		}

		.image-text-timeline {
			.timeline-title {
				@apply hidden;
			}
		}
	}
}