

.page-id-615 {
  fieldset {
    @apply mb-8;
  }
}

fieldset {
  @apply mb-8;

  legend {
    @apply w-full text-center text-header text-xl lg:text-23px leading-5 lg:leading-normal mb-10;
  }

  .single-field {
    @apply mb-5 last:mb-0;

    label {
      @apply hidden;

      &.visible {
        @apply block;
      }
    }

    &.checkbox {
      @apply mt-2;

      label {
        @apply text-base text-header tracking-tight block;

        a {
          @apply underline;
        }
      }

      .wpcf7-list-item {
        @apply ml-0; 
      }
    }

    &.last-row {
      @apply mb-0;
    }
  }

  &.last {
    @apply mb-14;
  }

  .row-wrap {
    @apply flex flex-row flex-wrap justify-between;

    .single-field {
      @apply mr-2;
      flex: 1 0 48%;

      @media (max-width:$md) {     
        @apply mr-0 mb-5;
        flex: 1 0 100%;
      }
    }
  }
}


fieldset {
 
  .single-field-light {
    @apply mb-2 last:mb-0;

    label {
      @apply hidden;

      &.visible {
        @apply block;
      }
    }

    &.checkbox {
      @apply mt-2;

      label {
        @apply text-base text-header tracking-tight block;

        a {
          @apply underline;
        }
      }

     
    }

    &.last-row {
      @apply mb-0;
    }

    &.wpcf7-checkbox {
      @apply mt-0;
      .wpcf7-list-item {
        @apply ml-0;
      }
    }
  }

  &.last {
    @apply mb-14;
  }

  .row-wrap {
    @apply flex flex-row flex-wrap justify-between pr-6 md:pr-0;

    .single-field {
      @apply mr-2;
      flex: 1 0 48%;

      @media (max-width:$md) {     
        @apply mr-0 mb-5;
        flex: 1 0 100%;
      }
    }
  }
}

.prose a:not(.btn) {
  @apply text-secondary;
}

.prose a.btn {
  @apply text-white font-semibold;
  text-decoration: none;
  display: inline-block;
  margin: 2rem 0;

  &:hover, &:focus {
    @apply bg-tertiary text-text;
  }
}

.intro {
  @apply text-header mb-2;
}

.gdpr {
  @apply mb-5;

  fieldset {
    @apply mb-0;

    .single-field {
      @apply inline-block mt-0 mr-5 mb-0;

      input[type="checkbox"] {
        margin-right: 5px;
      }

      label {
        @apply text-sm text-text;
      }
    }
  }
}

fieldset {
  input:not([type="checkbox"]) {
    @apply block text-left text-header h-12 outline-none bg-white appearance-none text-lg pb-5 border-b border-gray-200;
    transition: border-color 0.2s ease-in-out;

    &::placeholder {
      @apply font-default text-header text-lg tracking-tight;
    }

    &:focus {
    }

    &.bordered {
      @apply border-t border-l border-r border-black;
    }

    &.wpcf7-not-valid {
      border-color: red !important;
    }
  }

  textarea {
    @apply block w-full text-left text-header h-12 outline-none bg-white appearance-none text-lg pb-5 border-2 border-gray-200;
    transition: border-color 0.2s ease-in-out;
    resize: vertical;
    height: 150px;
  }

  input[type="checkbox"] {
    position: relative;
    margin-right: 10px;
    margin-top: 0;
    height: 15px;
    width: 15px;

    &:after {
      @apply border-gray-200 bg-white;
      position: absolute;
      border-width: 1px;
      height: 15px;
      width: 15px;
      content: '';
      left: 0px;
      top: 0px;
    }

    &:checked {
      &:after {
        @apply border-secondary bg-secondary;
      }
    }
  }

  select {
    @apply block w-full text-left text-white outline-none h-16 bg-transparent;
    appearance: none;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='13px' height='8px' viewBox='0 0 13 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 63.1 (92452) - https://sketch.com --%3E%3Ctitle%3EPath Copy%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3Crect id='path-1' x='0' y='0.5' width='1442' height='860' rx='2'%3E%3C/rect%3E%3C/defs%3E%3Cg id='Full-Fidelity' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Rideshur-for-Drivers' transform='translate(-1337.000000, -7385.000000)'%3E%3Cg id='CTA' transform='translate(0.000000, 7178.000000)'%3E%3Cg id='Group-21'%3E%3Cg id='Group-12'%3E%3Cg id='illo-tandem-bicycle@2x-32e9a4dbe09726624df2b39dcc5db46b9a2bb7321585542ac80f9e7a1a5c24d4'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Mask' fill='%23FAF8F6' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cpolyline id='Path-Copy' stroke='%23424E5C' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round' points='1338 208.5 1343.6 214.1 1349.2 208.5'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position: calc(100% - 20px) center;
    background-repeat: no-repeat;
  }

  input::placeholder-shown,
  textarea::placeholder-shown {
    @apply font-default text-header text-lg leading-5;
  }

  input::placeholder,
  textarea::placeholder {
    @apply font-default text-header text-lg leading-5;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    @apply font-default text-header text-lg leading-5;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    @apply font-default text-header text-lg leading-5;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    @apply font-default text-header text-lg leading-5;
  }

  input:-moz-placeholder,
  textarea:-moz-placeholder {
    @apply font-default text-header text-lg leading-5;
  }

  .submit-wrap {
    @apply w-full flex justify-center;
  }

  input[type="submit"] {
    @apply py-2.5 px-6 text-white text-base font-semibold rounded shadow bg-secondary cursor-pointer;

    &:hover {
      @apply bg-tertiary text-header;
    }
  }

  .wpcf7-form {

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    select {
      @apply w-full;
    }

    

    .wpcf7-response-output {
      display: none !important;

      &.default-show {
        display: block !important;
      }
    }

  }
  .single-field.checkbox label > br{
    display: none;
  }
}


fieldset.get-started {
  @apply px-4;

  .wpcf7-list-item label {
    white-space: nowrap;
  }
  div.checkbox {
    min-width: 10rem;
  }

  input:not([type="checkbox"]) {
    @apply block text-left w-full h-8 outline-none bg-white appearance-none pb-2 border-b border-secondary;
    color: #235553;
    font-family: "HalyardDisplay";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: border-color 0.2s ease-in-out;

    &::placeholder {
      @apply tracking-tight;
      font-family: "HalyardDisplay";
      font-size: 16px;
    }

    &:focus {}

    &.bordered {
      @apply border-t border-l border-r border-black;
    }

    &.wpcf7-not-valid {
      border-color: red !important;
    }

  }

  textarea {
    @apply block w-full text-base text-left h-12 outline-none bg-white appearance-none p-2 border border-secondary;
    font-family: "HalyardDisplay";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: border-color 0.2s ease-in-out;
    resize: vertical;
    height: 150px;
  }

  input[type="checkbox"] {
    position: relative;
    margin-right: 3px;
    margin-top: 0;
    height: 10px;
    width: 10px;
    appearance: none;
    border: 2px solid #67C300;
    border-radius: 50%; 
    background-color: #fff;

    &:checked {
      background-color: #67C300;
    }

    &:after {
      position: absolute;
      border-width: 2px;
      height: 10px;
      width: 10px;
      content: '';
      left: 0px;
      top: 0px;
      border-radius: 50%;
      visibility: hidden;
    }

    &:checked:after {
      background-color:  #67C300;
    }
  }

  select {
    @apply block w-full text-left text-white outline-none h-16 bg-transparent;
    appearance: none;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml,..."); /* Keeping your SVG background image */
    background-position: calc(100% - 20px) center;
    background-repeat: no-repeat;
  }

  input::placeholder-shown,
  textarea::placeholder-shown,
  input::placeholder,
  textarea::placeholder,
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  input::-moz-placeholder,
  textarea::-moz-placeholder,
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  input:-moz-placeholder,
  textarea:-moz-placeholder {
    font-family: "HalyardDisplay";
    font-size: 16px;
  }

  .submit-wrap {
    @apply w-full flex justify-center;
  }

  input[type="submit"] {
    @apply py-2.5 px-6 text-white text-base font-semibold rounded shadow bg-secondary cursor-pointer;

    &:hover {
      @apply bg-tertiary text-header;
    }
  }

  .wpcf7-form {
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    select {
      @apply w-full text-sm;
    }

    input[type="submit"] {}

    .wpcf7-response-output {
      display: none !important;

      &.default-show {
        display: block !important;
      }
    }
  }

  .single-field.checkbox label > br {
    display: none;
  }

  input[type="checkbox"], p, legend{
    color: #000;
    font-family: "HalyardDisplay";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: -0.06px;
    margin-bottom: 0;
    text-align: left;

    & a {
      @apply underline text-secondary;
    }
  }

  .get-started-legend { 
    line-height: 18px !important;
  }

  input[type="submit"] {
    @apply w-full text-center py-2.5 px-6 text-white text-base font-semibold rounded shadow bg-secondary cursor-pointer;

    &:hover {
      @apply bg-tertiary text-header;
    }
  }

  .wpcf7-spinner {
    @apply hidden;
  }

}

.submit-get-started input[type="submit"] {
  @apply text-center w-full py-2.5 px-6 text-white text-base font-semibold rounded shadow bg-secondary cursor-pointer;

  &:hover {
    @apply bg-tertiary text-header;
  }
}

.wpcf7-list-item {
  margin: 0 !important;
}

span.wpcf7-list-item-label {
  line-height: 1.5;
  display: inline-block;
  min-width: 9rem;
  cursor: pointer;
}

input[type="submit"] {
@apply py-2.5 px-6 text-white text-base font-semibold rounded shadow bg-secondary cursor-pointer;

  &:hover {
    @apply bg-tertiary text-header;
  }
}

  /* Typography for checkboxes and paragraphs */
  .get-started-legend { 
    text-align: start;
    margin-bottom: 0;
    color: #235553;
    font-family: "Halyard Display";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.06px;
    }




// New form styles 
.new-form-styles{

  &.green-bg{
    input[type="text"],
    input[type="email"]{
      color: #fff;
      border-color: #83BC56;

      &::placeholder{
        color: #fff;
      }
    }
  }

  input[type="text"],
  input[type="email"]{
    @apply block text-left w-full h-8 outline-none bg-transparent appearance-none mb-2 pb-2 border-b border-secondary;
    color: #235553;
    font-family: "HalyardDisplay";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: border-color 0.2s ease-in-out;

    &::placeholder{
      color: #235553;
    }
  }

  input[type="submit"],
  .wpcf7-download a{
    background-color: #83BC56;
    margin-top: 8px;
  }

  .form-end{
    @media (min-width: $md) {
      display: flex;
      align-items: center;
      column-gap: 24px;
      position: relative;
    }

    .form-consent{
      margin-top: 10px;
    }

    .wpcf7-spinner{
      position: absolute;
      top: 100%;
      right: 0;

      @media (min-width: $md) {
        left: 0;
        padding-top: 3px;
      }
    }
  }

  .wpcf7-gated-content{
    border: none;
    box-shadow: none;
    padding: 0;
    background-color: transparent;

    a.wpcf7-btn{
        margin: 0;
        line-height: 1.4;
        display: inline-block;
        width: auto;
        height: auto;
        background-color: #83BC56;
    }
  }
}
