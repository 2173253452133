.file-download-block{
    .block-heading{
    
        h2{
            font-size: 28px;
            line-height: 1.1;
            color: #83BC56;

            @media (min-width: $md) {
                font-size: 42px;
            }
        }
    }

    .body-copy p,
    .form-title{
        font-size: 12px;
        color: #235553;
        line-height: 1.6;

        @media (min-width: $md) {
            font-size: 16px;
            line-height: 1.6;
        }
    }

    .download-image{
        max-width: 460px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: $md) {
            margin-right: 0;
        }
    }
}
