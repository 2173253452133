.did-you-know{

    .block-heading{

        @media (min-width: $md) {
            max-width: 284px;
            min-width: 284px;
        }

        h2{
            font-size: 64px;
            line-height: 1.1;
            color: #83BC56;
        }
    }

    .bullet{
        border-bottom: 1px solid rgba(35, 85, 83, 0.25);

        .bullet-ellipsis{
            margin-top: 6px;
            margin-right: 20px;

            @media (min-width: $md) {
                margin-top: 10px;
            }
        }

        .bullet-text{
            font-size: 18px;
            font-weight: 600;
            color: #235553;
            line-height: 1.75rem;

            @media (min-width: $md) {
                font-size: 32px;
                line-height: 1.2;
            }

            p{

                a{
                    color: #83BC56;
                    text-decoration: underline
                }
            }
        }
    }
    
    .contact-text{

        p{
            color: #235553;
            font-size: 14px;

            @media (min-width: $md) {
                font-size: 20px;
            }
        }
    }
}