.page--webinar {
	section.body-content {
		.block-container {
			@media(max-width:$md) {
				@apply px-0;

				.webinar-body-content {
					@apply px-6;
				}
			}
		}
	}
}

// Webinar signup
.webinar-signup-cta{
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
}

// Webinar details
.webinar-details-wrap{
	border-top: 1px solid #83BC56;
}

.webinar-details{
	max-width: 75rem;
	margin: 0 auto;
}

// Carousel
.speakers-carousel-title{
	font-size: 32px;
	color: #235553;
	font-weight: 600;
}

.speakers-carousel {

	.glide__track{
		overflow: visible !important;
	}

	.glide__slides{
		@media (max-width: 767px) {
			display: block;
		}
	}

	.glide__bullet.glide__bullet--active{
		opacity: 1;
		width: 12px;
		height: 12px;
	}
}


// Speaker card
.speaker-card{
	border-radius: 4px;
	overflow: hidden;
	height: 480px;

	@media (min-width: 768px) {
		height: 566px;
	}

	&:hover{

		@media (min-width: 768px) {
			.speaker-overlay{
				bottom: 0;
			}
		}
	}

	.speaker-name{
		background: linear-gradient(180deg, rgba(35, 85, 83, 0.00) 50%, rgba(35, 85, 83, 0.75) 100%);

		h3{
			font-size: 32px;
			font-weight: bold;
			color: #F0F6F4;
		}

		p{
			font-size: 18px;
			color: #F0F6F4;
			opacity: .8;
		}
	}

	.speaker-overlay{
		background: rgba(35, 85, 83, 0.75);
		backdrop-filter: blur(8px);
		bottom: -100%;
		transition: all .5s;

		&.active{
			bottom: 0;
		}

		.title{
			color: #F0F6F4;
			opacity: .8;
		}
	}
}


// Card toggle
.plus-minus{
	.plus-toggle,
	.minus-toggle{
		background-color: #83BC56;
	}
	.plus-toggle{
		top: 0.5rem;
	}
	.minus-toggle,
	.plus-toggle.active{
		transform: rotate(-90deg);
	}
}
